.App {
  margin: 0 auto;
  // padding-bottom: 100px;
  min-height: 100vh;
  // text-align: center;
 }

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.mainContent {
  padding-left: calc(100vw - 100%);
}

body, html {
  background: #f0f2f5;
  min-height: 100vh;
  width: 100%;
  overflow-wrap: break-word;
}

#root {
  min-height: 100vh;
  width: 100%;
}

header {
  & > div {
    display: flex;
    height: 100%;
  }
  
  .center {
    flex: 1 0 0%;
    align-items: flex-start;
    justify-content: center;
    line-height: 1.15;
    display: flex;
    flex-direction: column;
    gap: 3px;
    
    * {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin: 0;
    }
  }
  
  .side {
    gap: 5px;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    padding: 5px 0;
    align-content: center;
    // background: lightpink;
    // left: calc(calc(100vw - 100%) * 1px);
  }
}

.ant-page-header-heading-left {
  flex-wrap: wrap;
}

@media only screen and (max-width: 800px) {
  .ant-page-header-heading-extra { display: none !important; }
  
  .ant-layout-header {
    padding: 0 15px !important;
    h4 {
      display: none;
    }
    
    .ant-typography {
      font-size: 16px;
      text-align: right;
    }
  }
  
  .walletBalance {
    display: none !important;
  }
  
  .profilePage .header {
    width: 100%;
    .addressUnit, .addressText, .ant-typography {
      width: 100%;
    }
  }
}

.profilePage {
  .header {
    .addressUnit {
      flex-direction: column;
      align-items: flex-start;
      
      canvas {
        height: 50px !important;
        width: 50px !important;
        border-radius: 50%;
      }
      
      * {
        line-height: 1 !important;
      }
    }
  }
}
.ant-layout-content {
  // padding: 25px max(calc((100vw - 750px) / 2), 15px) 25px;
  width: min(750px, calc(100% - 30px));
  // width: 100%;
  margin: 0 auto;
}

.ant-page-header-heading-extra, header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 0 !important;
  // margin-bottom: 0;
  
  & > * {
    margin: 0 !important;
    line-height: 1 !important;
  }
  
  * {
    line-height: 1 !important;
  }
  
  .logoutButton {
    margin: 0;
  }
  
  .anticon {
    display: flex;
    height: 15px;
    
    svg {
      height: 100%;
    }
  }
  
  .walletBalance, .ant-typography {
    display: flex;
    align-items: center;
  }
  
  .networkSwitchDropdown {
    display: none;
  }
  
  .walletBalance {
    font-size: 16px !important;
  }
  
  .anticon-copy {
    display: none;
  }
  
  .addressUnit > * {
    font-size: 20px !important;
    
    canvas {
      height: 20px !important;
      width: 20px !important;
    }
  }
}

.accountUnit {
  display: flex;
  
  .anticon-wallet {
    display: none;
  }
}

.topRight {
  & > div > div > span {
    display: flex;
  }
}

.addressUnit {
  display: flex;
  align-items: center;
  gap: 10px;
  
  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
}